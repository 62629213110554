import React from 'react';

import styles from './teacher.module.scss';

import teacherImg from '../../assets/img/said.a441c150e1d10c799bd2.jpeg';
const Teacher = () => {
  return (
    <>
      <div className={styles.teacher}>
        <img src={teacherImg} alt="Said Teacher" />
        <h2>Саид Мартазанов - преподаватель</h2>
        <p>
          Основатель и активный преподаватель школы программирования GREATCODE. В его
          образовательное портфолио входит — высшее техническое образование и успешное завершение
          IT-буткемпа. Саид обладает коммерческим опытом в области веб-разработки, работая над
          разнообразными проектами в различных компаниях и опытом преподавательской деятельности в
          сфере программирования. В настоящее время он занимает позицию фронтенд-разработчика в
          перспективном стартапе. Его обширный опыт в преподавании технических дисциплин делает его
          ценным ресурсом для студентов, стремящихся к развитию в сфере программирования.
        </p>
      </div>
    </>
  );
};

export default Teacher;
